import {
    axios
} from '@/utils/request'

const list = (parameter) => {
    return axios({
        url: '/department/list',
        method: 'post',
        data: parameter
    })
}
const add = (parameter) => {
    return axios({
        url: '/department',
        method: 'post',
        data: parameter
    })
}
const update = (parameter) => {
    return axios({
        url: '/department',
        method: 'PUT',
        data: parameter
    })
}
const del = (parameter) => {
    return axios({
        url: '/department',
        method: 'delete',
        params: parameter
    })
}
export {
    list,
    add,
    update,
    del
}
