<template>
    <a-modal
        :title="id?'修改用户信息':'新增用户信息'"
        :visible="visible"
        width="30%"
        :confirm-loading="confirmLoading"
        ok-text="确认"
        cancel-text="取消"
        @ok="handleConfirm"
        @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="用户姓名" v-bind="formItemLayout">
                <a-input
                        placeholder="用户姓名"
                        v-decorator="['userName',
            {rules: [{ required: true,max:6, message: '用户姓名必填、最大长度6个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="手机号" v-bind="formItemLayout">
                <a-input
                        placeholder="手机号"
                        v-decorator="['mobileNumber', {rules: [{ required: true, message: '请输入手机号码', whitespace: true},{validator:phoneCheck.bind(this)}]}]"
                />
            </a-form-item>
            <a-form-item label="密码" v-bind="formItemLayout">
                <a-input-password
                        size="large"
                        placeholder="请输入密码"
                        v-decorator="[
                'pwd',
                {rules: [{ required: !id?true: false, message: '请输入密码' },,{ pattern:  /^.{3,6}$/, message: '请输入3-6位密码！' }], validateTrigger: 'blur'}
              ]"
                >
                </a-input-password>
            </a-form-item>
            <a-form-item label="角色" v-bind="formItemLayout">
              <a-select v-decorator="['roleId',{rules: [{ required: true, message: '角色必选' }]}]"
                        placeholder="请选择角色">
                <a-select-option v-for="it in options" :key="it.id"
                                 :value="it.id">{{it.roleName}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属组织机构" v-bind="formItemLayout">
                <a-tree-select style="width: 70%"
                               @select="parentChange"
                               v-decorator="['departmentSn',{rules: [{ required: true, message: '请选择组织机构' }]}]"
                               :treeData="types"
                               placeholder='请选择组织机构'
                               treeDefaultExpandAll>
                </a-tree-select>
              <a-checkbox v-model="isMain" style="padding-left: 15px">
                负责人
              </a-checkbox>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {list} from '@/api/org'
    import {add,update,roleList} from '@/api/user'
    import md5 from 'js-md5';
    export default {
        props:['visible'],
       data(){
           return {
               form: this.$form.createForm(this),
               formItemLayout: {
                   labelCol: {
                       span: 6
                   },
                   wrapperCol: {
                       span: 18
                   }
               },
               maxLength:1000,
               confirmLoading: false,
               types:[],
               options:[],
               id:'',
             isMain:false
           }
       },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                    this.loadType()
                    this.getRole()
                }
            }
        },
        methods:{
            loadType() {
                list(this.searchForm).then((res)=>{
                  var departmentSn = JSON.parse(localStorage.getItem('userInfo')).departmentSn
                  var departmentName = JSON.parse(localStorage.getItem('userInfo')).departmentName
                    if (res.length > 0) {
                        this.types = [
                            {
                                key: departmentSn,
                                value: departmentSn,
                                title: departmentName,
                                children: this.convertToTreeData(res, departmentSn)
                            }
                        ];
                        console.log(this.types)
                    }
                })
            },
            convertToTreeData(data, pid) {
                const result = [];
                let temp = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].parentSn == pid) {
                        const obj = {
                            key: data[i].departmentSn,
                            value: data[i].departmentSn,
                            title: data[i].departmentName
                        };
                        temp = this.convertToTreeData(data, data[i].departmentSn);
                        if (temp.length > 0) {
                            obj["children"] = temp;
                        }
                        result.push(obj);
                    }
                }
                return result;
            },
            getRole() {
                const params = {
                    current:1,
                    size: -1,
                    classification: 1
                }
                roleList(params).then((res)=>{
                    this.options = res.records
                })
            },
            phoneCheck (rule, value, callbackFn) {
                const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if (!reg.test(value)) {
                    callbackFn('请输入正确的手机号码')
                    return
                }
                callbackFn()
            },
            parentChange(value, label, extra) {
                this.departmentSn = extra.node.dataRef.level
            },
            setFormValues(record) {
              console.log(record)
                Object.keys(record).forEach(key => {
                  this.form.getFieldDecorator(key);
                    let obj = {};
                    if(key == "id") {
                        this.id = record[key]
                    }else if(key =='isMain') {
                      this.isMain = record[key]
                    }else {
                        obj[key] = record[key];
                        this.form.setFieldsValue(obj);
                    }
                });
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    if(!err) {
                        values.isMain = this.isMain
                        if (!this.id) {
                          values.pwd = md5(values.pwd)
                            add(values).then((res) => {
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                          if(values.pwd){
                            values.pwd = md5(values.pwd)
                          }
                            values.id = this.id
                            update(values).then((res) => {
                                this.departmentSn = ''
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
                this.$emit('close')
                this.id=''
                this.form.resetFields()
            }
        }
    }
</script>

<style scoped>

</style>