const columns = [
        {
            title: '#',
            scopedSlots: { customRender: 'serial' }
        },
        {
            title: '用户姓名',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: '手机号',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
            ellipsis: true,
        },
        {
            title: '所属组织',
            key: 'departmentName',
            dataIndex: 'departmentName',
        },
        {
            title: '角色',
            key: 'roleName',
            dataIndex: 'roleName',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 140,
            scopedSlots: { customRender: 'action' },
        },
    ];
export {
    columns
}
